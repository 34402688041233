var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" } },
    [
      _c("title", [_vm._v("Format")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M14.9,5.35h.32A.78.78,0,0,0,16,4.57V4.25a.78.78,0,0,\n      0-.78-.78H14.9a.76.76,0,0,0-.76.69H10V3.47H8.08v.69H3.86a.76.76,0,\n      0,0-.76-.69H2.78A.78.78,0,0,0,2,4.25v.32a.78.78,0,0,0,.78.78H3.1a.76.76,\n      0,0,0,.76-.69H6.53A6.34,6.34,0,0,0,2.77,9.53H2v1.88H3.88V9.53h-.6a5.81,\n      5.81,0,0,1,4.8-4.79v.61H10v-.6a5.81,5.81,0,0,1,4.76,\n      4.78h-.6v1.88H16V9.53h-.77a6.34,6.34,0,0,0-3.76-4.87h2.67A.76.76,0,0,0,14.9,5.35Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M9.4,9.93a.69.69,0,0,1-.22,1.24h0L9,11.2l-.15,\n      0h0a.69.69,0,0,1-.19-1.26V6.54L6.23,10l1.67,4.5h2.2L11.77,10,9.4,6.59Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }